var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"9"}},[_c('book-list',{attrs:{"books":_vm.books}}),_c('div',{staticClass:"text-center mt-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.totalPages},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"md":"4","lg":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Generi")]),_c('v-card-text',_vm._l((_vm.genres),function(genre){return _c('span',{key:genre.id},[_c('router-link',{attrs:{"to":{
                  name: 'HomeFiltered',
                  params: { id: genre.id, key: 'genres' }
                }}},[_vm._v(_vm._s(genre.name))]),_vm._v(" | ")],1)}),0)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Autori")]),_c('v-card-text',_vm._l((_vm.authors),function(author){return _c('span',{key:author.id},[_c('router-link',{attrs:{"to":{
                  name: 'HomeFiltered',
                  params: { id: author.id, key: 'author' }
                }}},[_vm._v(_vm._s(author.name))]),_vm._v(" | ")],1)}),0)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Case editrici")]),_c('v-card-text',_vm._l((_vm.productors),function(productor){return _c('span',{key:productor.id},[_c('router-link',{attrs:{"to":{
                  name: 'HomeFiltered',
                  params: { id: productor.id, key: 'productor' }
                }}},[_vm._v(_vm._s(productor.name))]),_vm._v(" | ")],1)}),0)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }